import React from 'react';
import { graphql } from 'gatsby';

import SEO from 'src/components/Seo/Seo';
import MultistepForm from 'src/components/MultistepForm/MultistepForm';

interface UniqueTripFormProps {
  data: any;
}

const UniqueTripForm: React.FC<UniqueTripFormProps> = ({ data }) => {
  const {
    wpPage: { seo },
  } = data;

  return (
    <>
      <SEO
        title={seo?.title}
        description={seo?.metaDesc}
        image={seo?.opengraphImage?.localFile}
      />

      <MultistepForm />
    </>
  );
};

export const query = graphql`
  query UniqueTripForm {
    wpPage(slug: { eq: "unikali-kelione" }) {
      ...YoastSeoPage
    }
  }
`;

export default UniqueTripForm;
