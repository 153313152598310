import { css } from 'styled-components';

import { colors } from 'src/theme/colors';
import { mediaQuery } from 'src/theme/breakpoints';

export const baseInputActiveStyles = css`
  border: 3px solid ${colors.blue500};
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  transform: translateY(-5px);
`;

export const baseInputWrapperStyles = css`
  align-items: center;
  background-color: ${colors.white};
  border: 3px solid transparent;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 320px;
  padding: 40px 10px 20px;
  position: relative;
  transition: transform 200ms ease-in-out,
    box-shadow 300ms cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover,
  &:focus-within {
    ${baseInputActiveStyles}
  }

  ${mediaQuery('md')} {
    min-height: 360px;
    padding: 40px 20px;
  }
`;

export const baseFormTitleStyles = css`
  align-items: center;
  color: ${colors.blue500};
  display: flex;
  font-size: 18px;
  font-weight: 700;
  height: 40px;
  margin: 20px 0 10px;
  text-align: center;
  text-transform: uppercase;
`;
