import React, { useEffect, useState } from 'react';
import { Field, FieldArray, FormikContextType, useFormikContext } from 'formik';
import styled, { css } from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { colors } from 'src/theme/colors';
import { CheckboxGroupProps } from 'src/components/MultistepForm/shared/types';
import FormCustomInput from 'src/components/MultistepForm/FormCustomInput/FormCustomInput';
import { CheckIcon } from 'src/assets/icons';
import { mediaQuery } from 'src/theme/breakpoints';
import {
  baseInputWrapperStyles,
  baseFormTitleStyles,
  baseInputActiveStyles,
} from 'src/components/MultistepForm/shared/styles';
import { neutralOptionValue } from 'src/components/MultistepForm/shared/constants';

const GroupWrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr;

  ${mediaQuery('sm')} {
    grid-template-columns: 1fr 1fr;
  }

  ${mediaQuery('md')} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${mediaQuery('lg')} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const InputWrapper = styled.label<{ isChecked: boolean }>`
  ${baseInputWrapperStyles}

  ${props => props.isChecked && baseInputActiveStyles}
`;

const CheckmarkIcon = styled(CheckIcon)`
  display: none;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;

  & path {
    fill: ${colors.blue500};
  }
`;

const Checkbox = styled(Field)`
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;

  &:checked ~ ${CheckmarkIcon} {
    display: block;
  }
`;

const Title = styled.p<Pick<FormCheckboxGroupProps, 'isDays'>>`
  ${baseFormTitleStyles}

  ${({ isDays }) =>
    isDays &&
    css`
      font-size: 100px;
      height: auto;
      margin: 0;
    `}

  ${mediaQuery('lg')} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Image = styled(GatsbyImage)`
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  height: 160px;
  width: 100%;
`;

const Description = styled.p`
  margin: 0;
  text-align: center;
`;

interface FormCheckboxGroupProps {
  checkboxGroup: CheckboxGroupProps;
  isMultiple?: boolean;
  withCustomOption?: boolean;
  isDays?: boolean;
}

const FormCheckboxGroup: React.FC<FormCheckboxGroupProps> = ({
  checkboxGroup,
  isMultiple = true,
  withCustomOption = true,
  isDays,
}) => {
  const { values, setFieldValue }: FormikContextType<any> = useFormikContext();
  const [customOption, setCustomOption] = useState<number | string>('');

  const { name: groupName, options: groupOptions, customInput } = checkboxGroup;
  let boundArrayHelpers;

  useEffect(() => {
    setCustomOption(getCustomOption());
  }, []);

  const getCustomOption = (): string => {
    const availableOptions = groupOptions.map(option => option.value);
    const selectedOptions = values[groupName];

    const customOption = selectedOptions.filter(
      value => availableOptions.indexOf(value) === -1
    );

    return customOption[0] ?? '';
  };

  const addCustomOption = (value: string | number): void => {
    if (!isMultiple) boundArrayHelpers.pop();

    value
      ? boundArrayHelpers.push(value)
      : !isMultiple && setFieldValue(groupName, []);
  };

  const removeValue = (value: string | number): void => {
    const valueIndex = values[groupName].indexOf(value);
    valueIndex > -1 && boundArrayHelpers.remove(valueIndex);
  };

  const addValue = (value: string | number): void => {
    boundArrayHelpers.push(value);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;

    removeValue(customOption);
    setCustomOption(value);
    addCustomOption(value);
  };

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    !isMultiple && customOption && setCustomOption('');

    if (!e.target.checked && !isMultiple) return;

    const value = e.target.value;
    !isMultiple && boundArrayHelpers.pop();
    e.target.checked ? addValue(value) : removeValue(value);
  };

  return (
    <GroupWrapper role="group" aria-labelledby="checkbox-group">
      <FieldArray
        name={groupName}
        render={arrayHelpers => {
          boundArrayHelpers = arrayHelpers;
          return (
            <>
              {groupOptions.map(({ value, description, title, image }) => (
                <InputWrapper
                  key={value}
                  isChecked={values[groupName].includes(value)}
                >
                  <Checkbox
                    type="checkbox"
                    name={groupName}
                    value={value}
                    onChange={onCheckboxChange}
                  />
                  {image && (
                    <Image image={getImage(image.localFile)} alt={title} />
                  )}
                  {title && <Title isDays={isDays}>{title}</Title>}
                  {description && <Description>{description}</Description>}
                  <CheckmarkIcon />
                </InputWrapper>
              ))}
              <InputWrapper
                isChecked={values[groupName].includes(neutralOptionValue)}
              >
                <Checkbox
                  type="checkbox"
                  name={groupName}
                  value={neutralOptionValue}
                  onChange={onCheckboxChange}
                />
                <Title>Nesvarbu</Title>
                <CheckmarkIcon />
              </InputWrapper>
            </>
          );
        }}
      />

      {withCustomOption && (
        <FormCustomInput
          id={`${customInput?.title}-input`}
          name={`${customInput?.title}-input`}
          description={customInput?.description ?? ''}
          title={customInput?.title ?? ''}
          type={customInput?.type ?? 'text'}
          onChange={onInputChange}
          value={customOption}
          isDays={isDays}
        />
      )}
    </GroupWrapper>
  );
};

export default FormCheckboxGroup;
