import React, { FC, useEffect, useState } from 'react';
import { navigate } from 'gatsby';

import FormStepper from 'src/components/MultistepForm/FormStepper/FormStepper';
import Step1, { stepData1 } from 'src/components/MultistepForm/FormSteps/Step1';
import Step2, { stepData2 } from 'src/components/MultistepForm/FormSteps/Step2';
import Step3, { stepData3 } from 'src/components/MultistepForm/FormSteps/Step3';
import Step4, { stepData4 } from 'src/components/MultistepForm/FormSteps/Step4';
import Step5, { stepData5 } from 'src/components/MultistepForm/FormSteps/Step5';
import Step6, { stepData6 } from 'src/components/MultistepForm/FormSteps/Step6';
import Step7, { stepData7 } from 'src/components/MultistepForm/FormSteps/Step7';
import {
  initialCategoryFields,
  links,
} from 'src/components/MultistepForm/shared/constants';
import FormBackground from 'src/components/MultistepForm/FormBackground';
import { isAllFieldsFilled } from 'src/components/MultistepForm/shared/helpers';

const MultistepForm: FC = () => {
  const [values, setValues] = useState(initialCategoryFields);

  useEffect(() => {
    setValues(getValuesFromStorage);
  }, []);

  useEffect(() => {
    if (isAllFieldsFilled(values)) {
      navigate(links.TRIP_PLAN);
    }
  }, [values]);

  const getValuesFromStorage = () => {
    const storedFormValues = sessionStorage.getItem('formValues');

    return storedFormValues
      ? JSON.parse(storedFormValues)
      : initialCategoryFields;
  };

  const onSubmit = (): void => {
    navigate(links.TRIP_PLAN);
  };

  return (
    <FormBackground>
      <FormStepper initialValues={values} onSubmit={onSubmit}>
        <Step1
          validationSchema={stepData1.validationSchema}
          title={stepData1.title}
        />

        <Step2
          validationSchema={stepData2.validationSchema}
          title={stepData2.title}
        />

        <Step3
          validationSchema={stepData3.validationSchema}
          title={stepData3.title}
        />

        <Step4
          validationSchema={stepData4.validationSchema}
          title={stepData4.title}
        />

        <Step5
          validationSchema={stepData5.validationSchema}
          title={stepData5.title}
        />

        <Step6
          validationSchema={stepData6.validationSchema}
          title={stepData6.title}
        />

        <Step7
          validationSchema={stepData7.validationSchema}
          title={stepData7.title}
        />
      </FormStepper>
    </FormBackground>
  );
};

export default MultistepForm;
