import { graphql, useStaticQuery } from 'gatsby';
import { object, array, string } from 'yup';
import React from 'react';

import FormStep, {
  StepProps,
} from 'src/components/MultistepForm/FormStep/FormStep';
import FormCheckboxGroup from 'src/components/MultistepForm/FormCheckboxGroup/FormCheckboxGroup';
import { categoryFields } from 'src/components/MultistepForm/shared/constants';
import {
  getCheckboxGroupData,
  getCustomInputData,
} from 'src/components/MultistepForm/shared/helpers';

export const stepData4 = {
  validationSchema: object({
    [categoryFields.SEASON]: array().of(string().required('required')).min(1),
  }),
  title: 'Kada norite keliauti?',
};

const Step4: React.FC<StepProps> = ({ validationSchema, title }) => {
  const data = useStaticQuery(graphql`
    query Seasons {
      wpProductCategory(slug: { eq: "sezonas" }) {
        slug
        ...ProductChildCategories
      }
    }
  `);

  const customInputData = getCustomInputData(
    'text',
    'Konkreti data',
    'Įrašykite jus dominančią datą'
  );

  const checkboxGroup = getCheckboxGroupData(data, customInputData);

  return (
    <FormStep validationSchema={validationSchema} title={title}>
      <FormCheckboxGroup checkboxGroup={checkboxGroup} />
    </FormStep>
  );
};

export default Step4;
