import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components';

import { colors } from 'src/theme/colors';
import { baseInputWrapperStyles } from 'src/components/MultistepForm/shared/styles';

const InputWrapper = styled.div`
  ${baseInputWrapperStyles}
`;

const Input = styled.input`
  appearance: textfield;
  border: none;
  border-bottom: 1px solid ${colors.blue300};
  color: ${colors.blue500};
  font-size: 100px;
  font-weight: 700;
  padding: 10px;
  text-align: center;
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
`;

const Label = styled.label`
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
  text-align: center;
`;

const Description = styled.p`
  color: ${colors.blue300};
  text-align: center;
`;

interface FormInputProps {
  id: string;
  name: string;
  label: string;
  type: string;
  placeholder?: string;
  description?: string;
  onChange?: (e) => void;
  value?: any;
}

const FormInput: React.FC<FormInputProps> = ({
  label,
  description,
  value,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <InputWrapper>
      <Input {...field} {...props} value={value} />
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <Description>{description}</Description>
      {meta.touched && meta.error && <div>{meta.error}</div>}
    </InputWrapper>
  );
};

export default FormInput;
