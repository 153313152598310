import React from 'react';
import { FormikConfig, FormikValues } from 'formik';

export interface StepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  title: string;
}

const FormStep = ({ children }: StepProps): JSX.Element => {
  return <>{children}</>;
};

export default FormStep;
