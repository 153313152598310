import React, { useEffect, useState } from 'react';
import { Formik, Form, FormikConfig, FormikValues } from 'formik';

import { StepProps } from 'src/components/MultistepForm/FormStep/FormStep';
import FormNavigation from 'src/components/MultistepForm/FormNavigation/FormNavigation';
import { getFromStorage, setToStorage } from 'src/utils/helpers';
import { storageKeys } from 'src/components/MultistepForm/shared/constants';
import Main from 'src/components/Main';

const FormStepper = ({
  children,
  ...props
}: FormikConfig<FormikValues>): JSX.Element => {
  const stepsArray = React.Children.toArray(
    children
  ) as React.ReactElement<StepProps>[];
  const [currentStep, setStep] = useState(0);

  const currentStepElement = stepsArray[currentStep];
  const isLastStep = currentStep === stepsArray.length - 1;
  const isFirstStep = currentStep > 0;

  useEffect(() => {
    const currentStepFromStorage = Number(getFromStorage('currentStep'));
    setStep(currentStepFromStorage ?? 0);
  }, [props.initialValues]);

  const storeValuesToStorage = values =>
    setToStorage(storageKeys.FORM_VALUES, JSON.stringify(values));

  const storeStepToStorage = (step: number) =>
    setToStorage(storageKeys.CURRENT_STEP, String(step));

  const onSubmit = async (values, helpers) => {
    storeValuesToStorage(values);

    if (isLastStep) {
      await props.onSubmit(values, helpers);
      return;
    }

    setStep(s => s + 1);
    storeStepToStorage(currentStep + 1);
  };

  const onBackClick = (): void => {
    setStep(s => s - 1);
    storeStepToStorage(currentStep - 1);
  };

  return (
    <Formik
      initialValues={props.initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={currentStepElement?.props?.validationSchema}
    >
      <Form>
        <FormNavigation
          title={currentStepElement?.props.title}
          backActive={isFirstStep}
          isLastStep={isLastStep}
          onBackClick={onBackClick}
        />
        <Main>{currentStepElement}</Main>
      </Form>
    </Formik>
  );
};

export default FormStepper;
