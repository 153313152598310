import { graphql, useStaticQuery } from 'gatsby';
import { object, array, string } from 'yup';
import React from 'react';

import FormStep, {
  StepProps,
} from 'src/components/MultistepForm/FormStep/FormStep';
import FormCheckboxGroup from 'src/components/MultistepForm/FormCheckboxGroup/FormCheckboxGroup';
import { categoryFields } from 'src/components/MultistepForm/shared/constants';
import { getCheckboxGroupData } from 'src/components/MultistepForm/shared/helpers';

export const stepData6 = {
  validationSchema: object({
    [categoryFields.APARTMENTS]: array()
      .of(string().required('required'))
      .min(1),
  }),
  title: 'Kur norite gyventi?',
};

const Step6: React.FC<StepProps> = ({ validationSchema, title }) => {
  const data = useStaticQuery(graphql`
    query Apartments {
      wpProductCategory(slug: { eq: "apartamentai" }) {
        slug
        ...ProductChildCategories
      }
    }
  `);

  const checkboxGroup = getCheckboxGroupData(data);

  return (
    <FormStep validationSchema={validationSchema} title={title}>
      <FormCheckboxGroup
        checkboxGroup={checkboxGroup}
        withCustomOption={false}
      />
    </FormStep>
  );
};

export default Step6;
