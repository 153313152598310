import { object, number } from 'yup';
import React from 'react';
import { useFormikContext } from 'formik';

import FormStep, {
  StepProps,
} from 'src/components/MultistepForm/FormStep/FormStep';
import FormInput from 'src/components/MultistepForm/FormInput/FormInput';
import { categoryFields } from 'src/components/MultistepForm/shared/constants';

export const stepData1 = {
  validationSchema: object({
    [categoryFields.PEOPLE]: number()
      .min(1, 'Minimalus keliautojų skaičius: 1')
      .max(50, 'Maksimalus grupės dydis: 50')
      .required('Pasirinkite keliautojų skaičių'),
  }),
  title: 'Keliese keliausite?',
};

const Step1: React.FC<StepProps> = ({ validationSchema, title }) => {
  const { values } = useFormikContext<StepProps>();

  return (
    <FormStep validationSchema={validationSchema} title={title}>
      <FormInput
        label="Įveskite asmenų skaičių"
        name={categoryFields.PEOPLE}
        id={categoryFields.PEOPLE}
        value={values[categoryFields.PEOPLE]}
        type="number"
        description="Maksimalus keliautojų skaičius - 50"
        placeholder="1"
      />
    </FormStep>
  );
};

export default Step1;
