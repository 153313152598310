import { graphql, useStaticQuery } from 'gatsby';
import { object, array, string } from 'yup';
import React from 'react';

import FormStep, {
  StepProps,
} from 'src/components/MultistepForm/FormStep/FormStep';
import FormCheckboxGroup from 'src/components/MultistepForm/FormCheckboxGroup/FormCheckboxGroup';
import {
  getCheckboxGroupData,
  getCustomInputData,
} from 'src/components/MultistepForm/shared/helpers';
import { categoryFields } from 'src/components/MultistepForm/shared/constants';

export const stepData3 = {
  validationSchema: object({
    [categoryFields.ACTIVITIES]: array()
      .of(string().required('required'))
      .min(1),
  }),
  title: 'Kokia veikla jus domina?',
};

const Step3: React.FC<StepProps> = ({ validationSchema, title }) => {
  const data = useStaticQuery(graphql`
    query Activities {
      wpProductCategory(slug: { eq: "veiklos" }) {
        slug
        ...ProductChildCategories
      }
    }
  `);

  const customInputData = getCustomInputData(
    'text',
    'Kita',
    'Įveskite kitą norimą veiklą'
  );

  const checkboxGroup = getCheckboxGroupData(data, customInputData);

  return (
    <FormStep validationSchema={validationSchema} title={title}>
      <FormCheckboxGroup checkboxGroup={checkboxGroup} />
    </FormStep>
  );
};

export default Step3;
