import { object, array, string } from 'yup';
import React from 'react';

import FormStep, {
  StepProps,
} from 'src/components/MultistepForm/FormStep/FormStep';
import FormCheckboxGroup from 'src/components/MultistepForm/FormCheckboxGroup/FormCheckboxGroup';
import { categoryFields } from 'src/components/MultistepForm/shared/constants';
import { CheckboxGroupProps } from 'src/components/MultistepForm/shared/types';

export const stepData2 = {
  validationSchema: object({
    [categoryFields.DAYS]: array().of(string().required('required')).min(1),
  }),
  title: 'Kiek dienų keliausite?',
};

const Step2: React.FC<StepProps> = ({ validationSchema, title }) => {
  const checkboxGroup: CheckboxGroupProps = {
    name: categoryFields.DAYS,
    options: [
      {
        value: '7',
        title: '7',
      },
      {
        value: '10',
        title: '10',
      },
      {
        value: '14',
        title: '14',
      },
    ],
    customInput: {
      type: 'number',
      description: 'Įveskite kiek dienų norėtumėte keliauti',
    },
  };

  return (
    <FormStep validationSchema={validationSchema} title={title}>
      <FormCheckboxGroup
        checkboxGroup={checkboxGroup}
        isMultiple={false}
        isDays
      />
    </FormStep>
  );
};

export default Step2;
