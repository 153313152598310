import { graphql, useStaticQuery } from 'gatsby';
import { object, array, string } from 'yup';
import React from 'react';

import FormStep, {
  StepProps,
} from 'src/components/MultistepForm/FormStep/FormStep';
import FormCheckboxGroup from 'src/components/MultistepForm/FormCheckboxGroup/FormCheckboxGroup';
import { categoryFields } from 'src/components/MultistepForm/shared/constants';
import {
  getCheckboxGroupData,
  getCustomInputData,
} from 'src/components/MultistepForm/shared/helpers';

export const stepData5 = {
  validationSchema: object({
    [categoryFields.COUNTRIES]: array()
      .of(string().required('required'))
      .min(1),
  }),
  title: 'Kuriose šalyse keliausite?',
};

const Step5: React.FC<StepProps> = ({ validationSchema, title }) => {
  const data = useStaticQuery(graphql`
    query Countries {
      wpProductCategory(slug: { eq: "salys" }) {
        slug
        ...ProductChildCategories
      }
    }
  `);

  const customInputData = getCustomInputData(
    'text',
    'Kita',
    'Įveskite kitą šalį, kurioje norėtumėte keliauti'
  );

  const checkboxGroup = getCheckboxGroupData(data, customInputData);

  return (
    <FormStep validationSchema={validationSchema} title={title}>
      <FormCheckboxGroup checkboxGroup={checkboxGroup} />
    </FormStep>
  );
};

export default Step5;
