import React from 'react';
import styled from 'styled-components';

import { colors } from 'src/theme/colors';
import { mediaQuery } from 'src/theme/breakpoints';
import { CheckIcon } from 'src/assets/icons';
import {
  baseInputWrapperStyles,
  baseFormTitleStyles,
  baseInputActiveStyles,
} from 'src/components/MultistepForm/shared/styles';

const Wrapper = styled.div<{ isActive: boolean }>`
  ${baseInputWrapperStyles}

  ${props => props.isActive && baseInputActiveStyles}
`;

const CheckmarkIcon = styled(CheckIcon)`
  height: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;

  & path {
    fill: ${colors.blue500};
  }
`;

const Input = styled.input<FormCustomInputProps>`
  appearance: textfield;
  border: none;
  border-bottom: 1px solid ${colors.blue300};
  color: ${colors.blue500};
  font-size: ${props => (props.isDays ? '100px' : '12px')};
  font-weight: 700;
  padding: 10px;
  text-align: center;
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  ${mediaQuery('md')} {
    font-size: ${props => (props.isDays ? '60px' : '14px')};
  }

  ${mediaQuery('lg')} {
    font-size: ${props => (props.isDays ? '100px' : '18px')};
  }
`;

const Title = styled.p`
  ${baseFormTitleStyles}
  margin: 0;
`;

const Description = styled.p`
  margin: 0;
  text-align: center;
`;

interface FormCustomInputProps {
  id: string;
  name: string;
  label?: string;
  title?: string;
  type: string;
  placeholder?: string;
  description?: string;
  onBlur?: (e) => void;
  onChange?: (e) => void;
  onFocus?: (e) => void;
  value?: any;
  isDays?: boolean;
}

const FormCustomInput: React.FC<FormCustomInputProps> = ({
  label,
  description,
  value,
  isDays,
  title,
  ...props
}) => {
  return (
    <Wrapper isActive={value}>
      <Input isDays={isDays} {...props} value={value} />
      <label htmlFor={props.id || props.name}>{label}</label>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
      {value && <CheckmarkIcon />}
    </Wrapper>
  );
};

export default FormCustomInput;
